<template>
  <div class="md-layout-item">
    <div class="admins">
      <div class="admins-navbar">
        <md-tabs @md-changed="(tabId) => (activeTab = tabId)">
          <md-tab id="TabUsersInfo" md-label="Информация о пользователе" />
          <md-tab id="TabUserStatistic" md-label="Статистика" />
          <md-tab id="TabUsersBalance" md-label="Баланс и заявки" />
          <md-tab id="TabUsersTransaction" md-label="История транзакций" />
        </md-tabs>
      </div>
    </div>
    <div class="modal-content">
      <Transition name="fade" mode="out-in">
        <component :is="activeTab" />
      </Transition>
    </div>
    <div class="buttons-wrap justify-end">
      <md-button class="md-raised md-success">Изменить</md-button>
    </div>
  </div>
</template>

<script>
import TabUserStatistic from "@/components/Common/TabUserStatistic.vue";
import TabUsersBalance from "@/components/Common/TabUsersBalance.vue";
import TabUsersInfo from "@/components/Common/TabUsersInfo.vue";
import TabUsersTransaction from "@/components/Common/TabUsersTransaction.vue";
export default {
  components: {
    TabUserStatistic,
    TabUsersBalance,
    TabUsersInfo,
    TabUsersTransaction,
  },
  data() {
    return {
      activeTab: "TabUsersInfo",
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  background-color: #fff;
  padding: 20px;
}
.admins {
  margin-top: 40px;
}
.admins-wrap {
  padding-top: 40px;
}

.admins-navbar {
  margin-bottom: 20px;

  &::v-deep {
    .md-tabs-navigation {
      background-color: #58b05c !important;
      padding: 20px;
    }
  }
}
</style>
