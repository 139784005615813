<template>
  <md-table v-model="transactions">
    <md-table-row slot="md-table-row" slot-scope="{ item }">
      <md-table-cell md-label="Сумма пополнений">
        <span>{{ item.summ_add }}</span>
      </md-table-cell>
      <md-table-cell md-label="Сумма снятия">
        <span>{{ item.summ_remove }}</span>
      </md-table-cell>
      <md-table-cell md-label="Номер операции">
        <span>{{ item.id }}</span>
      </md-table-cell>
      <md-table-cell md-label="Статус">
        <span class="text ws-nowrap">{{ item.status }}</span>
      </md-table-cell>
      <md-table-cell md-label="Дата">
        <span class="text ws-nowrap">{{ item.date }}</span>
      </md-table-cell>
    </md-table-row>
  </md-table>
</template>

<script>
export default {
  data() {
    return {
      transactions: [
        {
          summ_add: 1200.3,
          summ_remove: 122.41,
          id: "1",
          status: "успешно",
          date: "23.02.2023",
        },
        {
          summ_add: 12020.3,
          summ_remove: 122.41,
          id: "2",
          status: "успешно",
          date: "23.03.2023",
        },
        {
          summ_add: 1200.3,
          summ_remove: 1222.41,
          id: "3",
          status: "успешно",
          date: "21.04.2023",
        },
        {
          summ_add: 12120.3,
          summ_remove: 12122.42,
          id: "4",
          status: "отменено",
          date: "20.08.2023",
        },
        {
          summ_add: 121.31,
          summ_remove: 1222.42,
          id: "5",
          status: "обрабатывается",
          date: "21.09.2023",
        },
        {
          summ_add: 12211.31,
          summ_remove: 122132.42,
          id: "6",
          status: "обрабатывается",
          date: "23.12.2023",
        },
        {
          summ_add: 1200.3,
          summ_remove: 122.41,
          id: "11",
          status: "успешно",
          date: "23.02.2023",
        },
        {
          summ_add: 12020.3,
          summ_remove: 122.41,
          id: "21",
          status: "успешно",
          date: "23.03.2023",
        },
        {
          summ_add: 1200.3,
          summ_remove: 1222.41,
          id: "31",
          status: "успешно",
          date: "21.04.2023",
        },
        {
          summ_add: 12120.3,
          summ_remove: 12122.42,
          id: "41",
          status: "отменено",
          date: "20.08.2023",
        },
        {
          summ_add: 121.31,
          summ_remove: 1222.42,
          id: "51",
          status: "обрабатывается",
          date: "21.09.2023",
        },
        {
          summ_add: 12211.31,
          summ_remove: 122132.42,
          id: "61",
          status: "обрабатывается",
          date: "23.12.2023",
        },
      ],
    };
  },
};
</script>
