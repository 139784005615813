<template>
  <ValidationObserver ref="addUserForm" :slim="true">
    <md-card-content class="info">
      <div class="info-header">
        <div class="info-header-avatar">
          <img src="@/assets/img/avatar.png" alt="avatar" />
        </div>
        <div class="md-layout md-gutter info-header-content">
          <template v-for="row in isNotAvaible">
            <div
              v-if="row.isAvaibleR"
              :key="`row_not_avaible_${row.label}`"
              class="md-layout-item md-size-100"
            >
              <label>{{ row.label }}: {{ row.value }}</label>
            </div>
          </template>
        </div>
      </div>
      <template v-for="row in isAvaible">
        <ValidationProvider
          v-if="row.isAvaibleR"
          :key="`row_avaible_${row.label}`"
          v-slot="{ errors, reset }"
          class="md-size-100"
          mode="passive"
          tag="div"
        >
          <md-field :class="{ error: errors.length }">
            <ValidationError :errors="errors" />
            <label>{{ row.label }}</label>
            <md-input v-model="row.value" @focus="reset" />
          </md-field>
        </ValidationProvider>
      </template>
      <div v-if="!blockAddedInfo" class="scins">
        <h4>Скины:</h4>
        <div class="scins-box">
          <img src="@/assets/img/scin.png" alt=" scin" />
          <img src="@/assets/img/scin_2.png" alt=" scin" />
          <img src="@/assets/img/scin_3.png" alt=" scin" />
        </div>
      </div>
    </md-card-content>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    blockAddedInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      info: [
        {
          label: "ID",
          value: "123",
          isAvaibleR: true,
          isAvaibleCU: false,
        },
        {
          label: "Уровень",
          value: "123",
          isAvaibleR: true,
          isAvaibleCU: false,
        },
        {
          label: "Ник",
          value: "vasya_123",
          isAvaibleR: true,
          isAvaibleCU: true,
        },
        {
          label: "E-mail",
          value: "vasya83@mail.ru",
          isAvaibleR: true,
          isAvaibleCU: true,
        },
        {
          label: "Статус",
          value: "online",
          isAvaibleR: true,
          isAvaibleCU: false,
        },
        {
          label: "Дата регистрации",
          value: "22.02.2023",
          isAvaibleR: true,
          isAvaibleCU: false,
        },
        {
          label: "Дата последней активности",
          value: "22.02.2023",
          isAvaibleR: true,
          isAvaibleCU: false,
        },
        {
          label: "Дата удаления",
          value: "22.02.2023",
          isAvaibleR: true,
          isAvaibleCU: false,
        },
        {
          label: "Дата восстановления аккаунта",
          value: "22.02.2023",
          isAvaibleR: true,
          isAvaibleCU: false,
        },
      ],
    };
  },
  computed: {
    isAvaible() {
      return this.info.filter((i) => i.isAvaibleCU);
    },
    isNotAvaible() {
      return this.info.filter((i) => !i.isAvaibleCU);
    },
  },
};
</script>

<style lang="scss">
.info {
  &-header {
    display: flex;
    gap: 20px;
    &-avatar img {
      width: 100px;
      min-width: 100px;
      height: 100px;
    }
  }
}
.scins {
  &-box {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    img {
      width: 80px;
      height: auto;
    }
  }
}
</style>
