<template>
  <div>
    <md-table v-model="itog">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="">
          <span>{{ item.label }}</span>
        </md-table-cell>
        <md-table-cell class="w-100px" md-label="Всего">
          <span class="text ws-nowrap">{{ item.itog }}</span>
        </md-table-cell>
        <md-table-cell class="w-100px" md-label="Зарики">
          <span class="text ws-nowrap">{{ item.zaricks }}</span>
        </md-table-cell>
        <md-table-cell class="w-100px" md-label="Слоты">
          <span class="text ws-nowrap">{{ item.slots }}</span>
        </md-table-cell>
        <md-table-cell class="w-100px" md-label="Дурак">
          <span class="text ws-nowrap">{{ item.fool }}</span>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-table v-model="stats">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="">
          <span>{{ item.label }}</span>
        </md-table-cell>
        <md-table-cell class="w-100px" md-label="1 место">
          <span class="text ws-nowrap">{{ item.first }} %</span>
        </md-table-cell>
        <md-table-cell class="w-100px" md-label="2 место">
          <span class="text ws-nowrap">{{ item.second }} %</span>
        </md-table-cell>
        <md-table-cell class="w-100px" md-label="3 место">
          <span class="text ws-nowrap">{{ item.third }} %</span>
        </md-table-cell>
        <md-table-cell class="w-100px" md-label="4 место">
          <span class="text ws-nowrap">{{ item.fourth }} %</span>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itog: [
        {
          label: "Игр сыграно",
          itog: 20,
          zaricks: 10,
          slots: 4,
          fool: 6,
        },
      ],
      stats: [
        {
          label: "Процент побед",
          first: 10,
          second: 20,
          third: 30,
          fourth: 40,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.w-100px {
  width: 100px;
}
</style>
