<template>
  <div>
    <ValidationObserver ref="balanceUser" :slim="true">
      <ValidationProvider
        v-slot="{ errors, reset }"
        class="md-size-100"
        mode="passive"
        tag="div"
      >
        <md-field :class="{ error: errors.length }">
          <ValidationError :errors="errors" />
          <label>Хард валюта</label>
          <md-input v-model="hard" @focus="reset" />
        </md-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, reset }"
        class="md-size-100"
        mode="passive"
        tag="div"
      >
        <md-field :class="{ error: errors.length }">
          <ValidationError :errors="errors" />
          <label>Софт валюта</label>
          <md-input v-model="soft" @focus="reset" />
        </md-field>
      </ValidationProvider>
    </ValidationObserver>
    <md-table v-model="orders">
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        @click="$router.push('/support')"
      >
        <md-table-cell md-label="Номер заявки">
          <span>{{ item.id }}</span>
        </md-table-cell>
        <md-table-cell md-label="Дата заявки">
          <span class="text ws-nowrap">{{ item.date }}</span>
        </md-table-cell>
        <md-table-cell md-label="Статус">
          <span class="text ws-nowrap">{{ item.status }}</span>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hard: 100,
      soft: 200,
      orders: [
        {
          id: "1",
          date: "23.02.2023",
          status: "успешно",
        },
        {
          id: "2",
          date: "23.03.2023",
          status: "успешно",
        },
        {
          id: "3",
          date: "21.04.2023",
          status: "успешно",
        },
        {
          id: "4",
          date: "20.08.2023",
          status: "отменено",
        },
        {
          id: "5",
          date: "21.09.2023",
          status: "обрабатывается",
        },
        {
          id: "6",
          date: "23.12.2023",
          status: "обрабатывается",
        },
      ],
    };
  },
};
</script>
