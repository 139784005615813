var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"balanceUser",attrs:{"slim":true}},[_c('ValidationProvider',{staticClass:"md-size-100",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Хард валюта")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.hard),callback:function ($$v) {_vm.hard=$$v},expression:"hard"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"md-size-100",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Софт валюта")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.soft),callback:function ($$v) {_vm.soft=$$v},expression:"soft"}})],1)]}}])})],1),_c('md-table',{scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{on:{"click":function($event){return _vm.$router.push('/support')}}},[_c('md-table-cell',{attrs:{"md-label":"Номер заявки"}},[_c('span',[_vm._v(_vm._s(item.id))])]),_c('md-table-cell',{attrs:{"md-label":"Дата заявки"}},[_c('span',{staticClass:"text ws-nowrap"},[_vm._v(_vm._s(item.date))])]),_c('md-table-cell',{attrs:{"md-label":"Статус"}},[_c('span',{staticClass:"text ws-nowrap"},[_vm._v(_vm._s(item.status))])])],1)}}]),model:{value:(_vm.orders),callback:function ($$v) {_vm.orders=$$v},expression:"orders"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }